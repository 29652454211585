import "intl-tel-input/build/css/intlTelInput.css";
import { setupPhoneCountryCode } from "../src/common/phoneCountryCode";

document.addEventListener("turbo:load", function (event) {
  setupPhoneCountryCode(document.querySelector(".format-phone-number"));
});

document.addEventListener("DOMContentLoaded", function (event) {
  setupPhoneCountryCode(document.querySelector(".format-phone-number"));
});
